<template>
  <div class="col-12 col-lg-6 c">
    <div class="card card-body text-center">
      <h2>جاري الدخول...</h2>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    $.post(api + "/client/auth/tahdir", {
      phone: window.location.href.split("/")[4],
      password: window.location.href.split("/")[5].split("?")[0],
    })
      .then(function (r) {
        if (r.status == 100) {
          localStorage.setItem("user", JSON.stringify(r.response));
          if (window.location.href.includes("backto")) {
            window.location = decodeURI(
              window.location.href.split("backto=")[1]
            ).replaceAll("%2F", "/");
          } else {
            window.location = "/";
          }
        } else {
          alert("البيانات غير صحيحة");
        }
      })
      .catch(function () {
        alert("حدث خطأ");
      });
  },
};
</script>

<style>
</style>